* {
	box-sizing: border-box;
}


html,
body {
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $bodyFont;
	font-size: 1.6rem;
	padding-top: 80px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $headerFont;
}

h3 {
	font-size: 2.3rem;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
		cursor: pointer;
	}
}

li {
	list-style-type: none;
}

ul {
	padding: 0;
	margin: 0;
}

h1 {
	font-size: $lgHeaderFontSize;

	@media (max-width: 600px) {
		font-size: 3.6rem;
	}

	@media (max-width: 450px) {
		font-size: 2.6rem;
	}
}

img {
	max-width: 100%;
}

.about-section,
.projects-list,
.contact-details {
	padding: 4rem;
	position: relative;

	@media (max-width: 768px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.sub-titles {
	margin-bottom: 4rem;
	text-align: center;

	h2 {
		font-size: 3.6rem;
		display: inline-block;

		&::after {
			content: '';
			display: block;
			width: 4rem;
			background-color: $secondaryColor;
			height: 4px;
		}
	}
}

.clear-btn {
	@include clearButtonlink($secondaryColor);
}

.full-btn {
	@include fullButtonlink($secondaryColor);
}

.icons {
	color: #fff;
}