.projects-list {
	background-color: #e4e2fe;
}

.projects-wrapper {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.project-block {
	flex-basis: 30%;
	background-color: #fff;
	margin: 2rem auto;
	padding: 2rem 2rem 7rem;
	position: relative;

	@media (max-width: 768px) {
		flex-basis: 100%;
		padding-bottom: 8rem;
	}

	h3 {
		border-bottom: 1px solid #e7e7e7;
	}

	a {
		color: $secondaryColor;
	}

	.description {
		margin-top: 2rem;
	}

	.links {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 2rem;

		.icons {
			color: $secondaryColor;
		}

		a {
			margin-right: 15px;
		}
	}

	.show-details {
		color: $mainColor;

		&:hover {
			cursor: pointer;
		}
	}
}