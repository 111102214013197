.nav-bar {
	background-color: $mainColor;
	padding         : 1rem 0;
	position        : fixed;
	left            : 0;
	right           : 0;
	top             : 0;
	z-index         : 1;
	box-shadow      : 0px 7px 10px -9px #4d4d4d;

	.logo {
		font-size : 4rem;
		color     : #fff;
		transition: font-size 300ms ease;

		&.small {
			font-size: 2rem;
		}
	}
}

.nav-wrapper {
	display        : flex;
	justify-content: space-between;
	align-items    : center;

	@media (max-width: 480px) {
		flex-direction: column;
	}
}

nav {
	a {
		color  : #fff !important;
		padding: 1rem 1rem 0.5rem;

		&:hover {
			color        : #fff;
			border-bottom: 2px solid #fff;
		}
	}

	.active {
		border-bottom: 2px solid #fff;
	}

	ul {
		display: flex;
	}

	li {
		margin: 0 1rem;
	}
}