@mixin clearButtonlink($color) {
	color     : $color;
	border    : 2px solid $color;
	padding   : 0.5rem 1rem;
	display   : inline-block;
	transition: color 300ms, background-color 300ms ease;

	&:hover {
		color           : #fff;
		background-color: $color;
	}
}

@mixin fullButtonlink($color) {
	color           : #fff;
	background-color: $color;
	border          : 2px solid $color;
	padding         : 0.5rem 1rem;
	display         : inline-block;
	transition      : color 300ms, background-color 300ms ease;

	&:hover {
		color           : $color;
		background-color: transparent;
	}
}