.modal-header {
	background-color: $mainColor;
	color           : #fff;

	.modal-title {
		font-size: 2rem;
	}

	.close {
		font-size: 4rem;
		padding  : 0 1rem;
		color    : #fff;
	}
}

.modal-body {

	h4 {
		border-bottom: 2px solid $secondaryColor;
		padding      : 0.5rem 0;
		color        : $mainColor;
		font-size    : 1.75rem;
	}

	ul {
		margin-top: 1rem;
	}

	li {
		border-bottom: 1px solid #e7e7e7;
		padding      : 5px 0;

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.modal-footer {
	button {
		font-size       : 1.5rem;
		background-color: $mainColor;
	}
}