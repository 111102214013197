.jumbo-intro {
	background-color: $sandyBeige;
	min-height      : 100vh;
}

.intro-wrapper {
	display        : flex;
	align-items    : center;
	justify-content: center;
	min-height     : calc(100vh - 6.4rem);
}

.intro-content {
	text-align: center;

	span {
		color: $secondaryColor;
	}

	h2 {
		font-size: $subtitleFontSize;

		@media (max-width: 600px) {
			font-size: 2.6rem;
		}

		@media (max-width: 450px) {
			font-size: 2rem;
		}
	}

	a {
		// margin-top: 1.5rem;
		margin       : 1.5rem 1rem;
	}
}