.about-section {

	.bar {
		background-color: #FC354B;
		width: 20%;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
}

// Quick Info section
.quick-info {
	.block {
		text-align: center;
		width: 80%;
		margin: 2rem auto;
		padding: 5rem 2rem;
		box-shadow: 1px 1px 5px 0px rgba(169, 167, 165, 0.8);
		// height          : 100%;
		background-color: #fff;

		@media (max-width: 900px) {
			width: 100%;
		}

		h3 {
			color: $secondaryColor;
			font-size: 2.5rem;
		}

		p {
			font-size: 2rem;
		}
	}

	.icon-wrapper {
		border-radius: 50%;
		background-color: #13747D;
		width: 10rem;
		height: 10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto 2rem;
	}
}

.detailed-info {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;

	@media (max-width: 768px) {
		display: block;
	}

	.tech-stack,
	.about-detail {
		padding: 4rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

// Tech stack column
.tech-stack {
	background-color: $mainColor;
	text-align: center;

	h2 {
		color: #fff;
	}
}

.tech-flex {
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 480px) {
		justify-content: space-evenly;
	}

	.flex-item {
		flex-basis: 30%;
		padding: 3rem;
		text-align: center;

		@media (max-width: 480px) {
			padding: 1.5rem;
		}
	}

	p {
		color: #fff;
		font-size: 2rem;
		margin-top: 1rem;
	}
}

// About me content
.about-detail {
	max-width: 500px;
	margin: auto;
	text-align: center;
	font-size: 1.8rem;

	.img-wrapper {
		max-width: 300px;
		width: 100%;
		margin: auto;
	}

	.description {
		margin-top: 2rem;
	}
}