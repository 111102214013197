.contact-details {
	text-align: center;
	font-size: 1.8rem;
}

.resume {
	color: $textColor;
	position: relative;

	&:after {
		position: absolute;
		background-color: rgba(252, 53, 75, 0.35);
		content: "";
		left: 0;
		right: 0;
		bottom: 3px;
		height: 30%;
		z-index: -1;
		transition: height 300ms;
	}

	&:hover {
		color: $textColor;

		&:after {
			background-color: rgba(252, 53, 75, 0.65);
			height: 80%;
		}
	}
}

.connect-links {
	.icons {
		color: $mainColor;
	}

	a {
		margin: 2rem;
		display: inline-block;
		color: $mainColor;
	}
}