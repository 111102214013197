// Font Family
$bodyFont: 'Pontano Sans',
sans-serif;
$headerFont: 'Roboto',
sans-serif;

// Colors
$mainColor     : #13747D;
$secondaryColor: #FC354B;
$textColor     : #28231E;
$sandyBeige    : #FCF7C5;

// Font Size
$lgHeaderFontSize : 5rem;
$subtitleFontSize : 3.6rem;
$quickIconFontSize: 4rem;